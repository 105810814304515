import React from "react";

import DefaultLayout from "../../../layouts/default";

const Partner = () => (
  <DefaultLayout title="Partner">
    <div className="orange portrait">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li className="is-active">
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productText">
              <span className="line">Partner&nbsp;</span>
              <span className="line">der WigaSoft AG</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/portrait/">Übersicht</a>
            </li>
            <li>
              <a href="/portrait/lehrlinge/">Lernende</a>
            </li>
            <li>
              <a className="is-active" href="/portrait/partner/">
                Partner
              </a>
            </li>
            <li>
              <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
            </li>
            <li>
              <a href="/portrait/kontakt/">Kontakt</a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top">
            <h1>Partner</h1>
            <p>
              Die WigaSoft AG setzt auf starke Partnerschaften mit bewährten und
              etablierten Anbietern von Dienstleistungen und Werkzeugen für das
              Gesundheitswesen. Auf dieser Basis können komplexe, durchgängige
              und für jeden Anwender spezifisch passende IT Lösungen erfolgreich
              gestaltet werden.
            </p>
          </section>

          <section className="has-padding-top has-padding-large-bottom">
            <div className="partnerFlex">
              <div className="partnerVertFlexOneCol">
                <div className="partnerItem">
                  <img
                    className="has-height has-width-116 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/ALIS.svg"
                  />
                  <h4>ALIS Connect</h4>
                  <p className="fliesstext">
                    <span className="medium">
                      Standardschnittstelle für Leistungsabrechnung auf der
                      Basis von XML
                    </span>
                    <br />
                    ALIS ist seit 2002 die Standardschnittstelle auf der Basis
                    von XML für die Leistungsübermittlung zwischen
                    Informatiksystemen in Spitälern (reine Erfassungssysteme,
                    RIS, KIS etc. zu Abrechnungssystemen). In einer breiten
                    Vernehmlassung wurde die Spezifikation mit weiteren
                    Anbietern und Spitälern abgestimmt.
                  </p>
                  <a href="https://www.alis-connect.ch/" target="_blank">
                    <button className="large notThatLarge">ALIS Connect</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-89 has-width-204 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/GoEast.svg"
                  />
                  <h4>GoEast GmbH</h4>
                  <p className="fliesstext">
                    Die GoEast GmbH erstellt komplexe Geschäftsanwendungen und
                    schön gestaltete Websites und sorgt für den reibungslosen
                    Betrieb auf leistungsfähigen Cloudplattformen. Dienste wie
                    E-Mail, Spamfilterung und DNS gehören ebenfalls zum Angebot.
                    GoEast ist ein langjähriger Partner der WigaSoft AG und eine
                    der Firmen in unserer Bürogemeinschaft an der Oberstrasse
                    222 in St.&nbsp;Gallen.
                  </p>
                  <a href="https://www.goeast.ch/" target="_blank">
                    <button className="large notThatLarge">GoEast</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-64 has-width-169 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/ID_Suisse.svg"
                  />
                  <h4>ID Suisse AG</h4>
                  <p className="fliesstext">
                    Mit der Firma ID verbindet die WigaSoft AG ebenfalls eine
                    langjährige Zusammenarbeit.
                    <br />
                    <br />
                    Die ID Suisse AG ist seit vielen Jahren in der Schweizer
                    Spitalszene aktiv und hat sich in allen drei Sprachregionen
                    einen guten Namen gemacht. Aktuell betreut die ID Suisse AG
                    rund 150 Kunden in der Schweiz. Sie stellt intelligente
                    Software für das Codieren von Diagnosen und Prozeduren in
                    den verschiedensten Klassifikationen, Grouper für die
                    Entgeltermittlung in fallpauschalenbasierten
                    Abrechnungssystemen sowie Systeme zur Leistungsanalyse und
                    Arzneimitteltherapiesicherheit zur Verfügung.
                  </p>
                  <a href="https://www.id-suisse-ag.ch/" target="_blank">
                    <button className="large notThatLarge">ID Suisse</button>
                  </a>
                </div>

                {/* <!--ThirdColumn Items--> */}
                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 has-width-267 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/NIC.svg"
                  />
                  <h4>NIC - Elsevier Inc.</h4>
                  <p className="fliesstext">
                    Die WigaSoft AG hat mit Elsevier Inc. (Mosby) eine
                    entsprechende Lizenzvereinbarung für die Weitergabe von
                    NIC-Sublizenzen unterzeichnet. Für die Nutzung der deutschen
                    Übersetzung haben wir zudem eine Vereinbarung mit der
                    Hogrefe AG.
                  </p>
                  <a href="https://www.elsevier.com/" target="_blank">
                    <button className="large notThatLarge">Elsevier</button>
                  </a>
                </div>

                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-89 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/Recom.png"
                  />
                  <h4>Recom GmbH</h4>
                  <p className="fliesstext">
                    RECOM ist offizieller Lizenzinhaber von NANDA-I für den
                    deutschsprachigen Raum und übersetzt die englischsprachige
                    Originalausgabe ins Deutsche. Zwischen der Recom GmbH und
                    der WigaSoft AG besteht eine Kooperations-Partnerschaft. Die
                    Lizenzen für den Einsatz der NANDA-I-Pflegediagnosen können
                    über die WigaSoft bezogen werden.
                  </p>
                  <a href="https://www.recom.eu/" target="_blank">
                    <button className="large notThatLarge">RECOM</button>
                  </a>
                </div>
                {/* <!--ThirdColumn Items over--> */}
              </div>
              <div className="partnerVertFlexOneCol">
                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/Imilia_Logo.png"
                  />
                  <h4>Imilia Interactive Mobile Applications GmbH</h4>
                  <p className="fliesstext">
                    Mit Timerbee einfach besser planen – das Termin- und
                    Ressourcenmanagement von Imilia. Mit Timerbee sind
                    Patienten, Belegärzte und Zuweiser jederzeit für
                    Terminbuchungen erreichbar. Standortübergreifende Termine
                    können im eigenen Terminportal einfach gesteuert werden, die
                    Zuweisung erfolgt schnell und sicher. WiCare|now LE kann
                    direkt aus Timerbee aufgerufen werden.
                  </p>
                  <a href="https://www.timerbee.com/" target="_blank">
                    <button className="large notThatLarge">
                      Imila – Timerbee
                    </button>
                  </a>
                </div>
                <div className="partnerItem">
                  <img
                    className="has-height smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/LEP-neu.png"
                  />
                  <h4>LEP AG</h4>
                  <p className="fliesstext">
                    LEP ist eine Leistungsklassifikation für die einheitliche
                    Dokumentation von Leistungen im Gesundheitswesen. Gemeinsam
                    mit der Firma LEP AG haben wir die Methode LEP entwickelt.
                    Wir haben die Methode und unsere Software WiCare|LEP in über
                    50 Spitäler in der Schweiz und in Deutschland eingeführt.
                  </p>
                  <a href="http://www.lep.ch/" target="_blank">
                    <button className="large notThatLarge">LEP AG</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/Nanda.png"
                  />
                  <h4>NANDA International</h4>
                  <p className="fliesstext">
                    Bei der amerikanischen Pflegeklassifikation NANDA-I
                    International handelt es sich um Beschreibungen der
                    Pflegesituation. Die Organisation NANDA International
                    (NANDA-I) macht es sich seit rund 40 Jahren zur Aufgabe,
                    eine standardisierte Taxonomie basierend auf Pflegediagnosen
                    weltweit zur Verfügung zu stellen.
                  </p>
                  <a href="http://www.nanda.org/" target="_blank">
                    <button className="large notThatLarge">NANDA</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-64 has-width-265 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/netspider-logo.svg"
                  />
                  <h4>NetSpider GmbH</h4>
                  <p className="fliesstext">
                    Mit der Firma NetSpider GmbH verbindet die WigaSoft AG eine
                    langjährige Partnerschaft im Bereich der Entwicklung,
                    Optimierung und Betreuung von Netzwerken für höchste
                    Ansprüche.
                  </p>
                  <a href="https://www.netspider.ch/" target="_blank">
                    <button className="large notThatLarge">NetSpider</button>
                  </a>
                </div>

                {/* <!--ThirdColumn Items--> */}
                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/rodix2x.png"
                  />
                  <h4>rodix – reto odermatt gmbh</h4>
                  <p className="fliesstext">
                    tacs<sup>®</sup> ist ein Controllingsystem zur
                    Tätigkeitsanalyse mit integrierter Leistungserfassung und
                    Abbildung der effektiven finanziellen Konsequenzen. tacs
                    <sup>®</sup>
                    eignet sich für den Einsatz bei verschiedenen Berufsgruppen.
                    Die WigaSoft liefert mit WiCare|tacs<sup>®</sup> seit 2006
                    die Software zur Methode tacs<sup>®</sup>.
                  </p>
                  <a href="https://www.rodix.ch/" target="_blank">
                    <button className="large notThatLarge">rodix</button>
                  </a>
                </div>

                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/amnexis.png"
                  />
                  <h4>AMNEXIS</h4>
                  <p className="fliesstext">
                    AMNEXIS ist Vorreiter für KI-gestützte virtuelle Assistenten in der medizinischen Versorgung. 
                    Die mobilen Apps sollen die Dokumentationszeit um bis zu 50% reduzieren und gleichzeitig die 
                    Datenqualität verbessern und eine Echtzeit-Synchronisation von Patientendaten von mobilen 
                    Eingabesystemen mit Datenverwaltungssystemen ermöglichen.
                  </p>
                  <a href="https://quixxs.com/" target="_blank">
                    <button className="large notThatLarge">AMNEXIS</button>
                  </a>
                </div>

                {/* <!--ThirdColumn Items over--> */}
              </div>
              <div className="partnerVertFlexOneCol thirdColumn">
                <div className="partnerItem">
                  <img
                    className="has-height-64 has-width-267 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/NIC.svg"
                  />
                  <h4>NIC - Elsevier Inc.</h4>
                  <p className="fliesstext">
                    Die WigaSoft AG hat mit Elsevier Inc. (Mosby) eine
                    entsprechende Lizenzvereinbarung für die Weitergabe von
                    NIC-Sublizenzen unterzeichnet. Für die Nutzung der deutschen
                    Übersetzung haben wir zudem eine Vereinbarung mit der
                    Hogrefe AG.
                  </p>
                  <a href="https://www.elsevier.com/" target="_blank">
                    <button className="large notThatLarge">Elsevier</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-89 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/Recom.png"
                  />
                  <h4>Recom GmbH</h4>
                  <p className="fliesstext">
                    RECOM ist offizieller Lizenzinhaber von NANDA-I für den
                    deutschsprachigen Raum und übersetzt die englischsprachige
                    Originalausgabe ins Deutsche. Zwischen der Recom GmbH und
                    der WigaSoft AG besteht eine Kooperations-Partnerschaft. Die
                    Lizenzen für den Einsatz der NANDA-I-Pflegediagnosen können
                    über die WigaSoft bezogen werden.
                  </p>
                  <a href="https://www.recom.eu/" target="_blank">
                    <button className="large notThatLarge">RECOM</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/rodix2x.png"
                  />
                  <h4>rodix – reto odermatt gmbh</h4>
                  <p className="fliesstext">
                    tacs<sup>®</sup> ist ein Controllingsystem zur
                    Tätigkeitsanalyse mit integrierter Leistungserfassung und
                    Abbildung der effektiven finanziellen Konsequenzen. tacs
                    <sup>®</sup>
                    eignet sich für den Einsatz bei verschiedenen Berufsgruppen.
                    Die WigaSoft liefert mit WiCare|tacs<sup>®</sup> seit 2006
                    die Software zur Methode tacs<sup>®</sup>.
                  </p>
                  <a href="https://www.rodix.ch/" target="_blank">
                    <button className="large notThatLarge">rodix</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/amnexis.png"
                  />
                  <h4>AMNEXIS</h4>
                  <p className="fliesstext">
                    AMNEXIS ist Vorreiter für KI-gestützte virtuelle Assistenten in der medizinischen Versorgung. 
                    Die mobilen Apps sollen die Dokumentationszeit um bis zu 50% reduzieren und gleichzeitig die 
                    Datenqualität verbessern und eine Echtzeit-Synchronisation von Patientendaten von mobilen 
                    Eingabesystemen mit Datenverwaltungssystemen ermöglichen.
                  </p>
                  <a href="https://quixxs.com/" target="_blank">
                    <button className="large notThatLarge">AMNEXIS</button>
                  </a>
                </div>



              </div>
            </div>
          </section>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/portrait/">Über uns</a>
                </li>
                <li>
                  <a href="/portrait/partner/">Partner</a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default Partner;
